// create an array object with the information from DOM

/**
 * 
 * @param {element} materialListElement 
 * takes a DOM element and creates an array from it
 * 
 */
function createMaterialsArray(materialListElement) {
  let materialList = [];
  // pull text from <li> children
  // note: make sure content editors don't nest lists

  // turn node list into array object
  let liArray = Array.prototype.slice.call(materialListElement);
  // create and return an array of materials
  return liArray;
}

function insertListClasses(listItemArray) {
  let alteredArr = listItemArray.map(el =>
    el.classList.add('material__list-item'),
  );
  return alteredArr;
}

function insertCheckbox(listItemArray) {
  let alteredArr = listItemArray.map((el, index) => {
    let val = el.textContent;
    let HTMLToInsert = `<div class="mdc-form-field">
      <div class="mdc-checkbox">
        <input type="checkbox" class="mdc-checkbox__native-control"
        id="material-${index}" />
        <div class="mdc-checkbox__background">
          <svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
            <path class="mdc-checkbox__checkmark__path"
              fill="none"
              stroke="white"
              d="M1.73,12.91 8.1,19.28 22.79,4.59" />
            </path>
          </svg>
          <div class="mdc-checkbox__mixedmark"></div>
        </div>
      </div>
      <label for="material=${index}">${val}</label>
    </div>`;
    el.innerHTML = HTMLToInsert;
    // let checkboxToInsert = `<input class='js-material-checkbox mdc-' type='checkbox' value='${val}'/>`;
    // el.insertAdjacentHTML('beforeend', checkboxToInsert);
    return el;
  });
  return alteredArr;
}

export default function createInteractiveMaterialsList(materialsLiElements) {
  return insertListClasses(
    insertCheckbox(createMaterialsArray(materialsLiElements)),
  );
}
