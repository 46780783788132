// init libraries
import 'jquery';
import fontawesome from '@fortawesome/fontawesome';
import faSearch from '@fortawesome/fontawesome-free-solid/faSearch';
import {MDCCheckbox, MDCCheckboxFoundation} from '@material/checkbox';

// init modules
import createInteractiveMaterialsList from './modules/materials.js';
//import './modules/timeliner.min.js';

// init font-awesome
fontawesome.library.add(faSearch);

// instantiate checkboxes
document.querySelectorAll('.mdc-checkbox').forEach(el => {
  MDCCheckbox.attachTo(el);
});


// function to check if its in an iframe (in CMSAdmin)
function isPageInFrame() {
  if (window.top != window.self) {
    console.log('window is in iframe');
    return true;
  }
  console.log('window is not in iframe');
  return false;
}

// if the page is in an iframe, add modifier class to body
// this makes helper labels that have to show in the Admin visible
$(() => {
  if (isPageInFrame()) {
    console.log('adding class to body tag')
    $('body').addClass('is-in-admin');
	}
});

// if .js-materials exists, create list
if (document.querySelector('.js-materials')) {
  // maybe add this to module?
  document.querySelector('.js-materials > ul').classList.add('list-unstyled');
  let materialsEls = document.querySelectorAll('.js-materials > ul > li');
  createInteractiveMaterialsList(materialsEls);
}

// show maintenance window message unless flag in sessionStorage is set
$(document).ready(function() {
  var showMaintenanceWindow =
    sessionStorage.getItem('showMaintenanceWindow') || 'true';
  if (
    document.querySelector('.maintenance-popup') &&
    showMaintenanceWindow === 'true'
  ) {
    document.querySelector('.maintenance-popup').classList.remove('invisible');
  }
});

// click listener called by button's OnClientClick method
window.HideMaintenancePopup = function() {
  sessionStorage.setItem('showMaintenanceWindow', 'false');
  document.querySelector('.maintenance-popup').classList.add('invisible');
  return false; // prevent postback
};
